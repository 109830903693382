import { action, thunk, Action, Thunk, createStore } from 'easy-peasy'
import { Auth } from 'aws-amplify'
import UserType from '../../types/user'

interface User {
  item: UserType
  isLoggedIn: boolean
  fetch: Thunk<User>
  setUser: Action<User, UserType>
  setIsLoggedIn: Action<User, boolean>
} 

export default {
  item: null,
  isLoggedIn: false,

  fetch: thunk(async (actions) => {
    try { 
      const data = await Auth.currentAuthenticatedUser()
      actions.setIsLoggedIn(true)
      actions.setUser(data as UserType)
    } catch (err) {
    }
  }),

  setUser: action((state, payload: UserType) => {
    state.item = payload
  }),

  setIsLoggedIn: action((state, payload: boolean) => {
    state.isLoggedIn = payload
  })
} as User
